'use client';

import { DatePicker } from '@/components/DatePicker';
import { EditPostAssetItemsDialog } from '@/components/File';
import { FormItem } from '@/components/Form';
import { Select, SelectTime } from '@/components/Select';
import { CreateGbpPostBody } from '@/lib/api/schema';
import { GbpActionTypeOption, GbpPostTypeOption } from '@/types/post';
import { HelpText } from '@/types/store';
import { Flex, Input, Switch, Text, Textarea } from '@chakra-ui/react';
import { formatDate } from 'date-fns';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const postTypeOptions: GbpPostTypeOption[] = [
  {
    value: 'STANDARD',
    label: '最新情報',
  },
  {
    value: 'EVENT',
    label: 'イベント',
  },
  {
    value: 'OFFER',
    label: '特典',
  },
];

const actionTypeOptions: GbpActionTypeOption[] = [
  {
    value: 'ACTION_TYPE_UNSPECIFIED',
    label: 'なし',
  },
  {
    value: 'BOOK',
    label: '予約',
  },
  {
    value: 'ORDER',
    label: 'オンライン注文',
  },
  {
    value: 'SHOP',
    label: '購入',
  },
  {
    value: 'LEARN_MORE',
    label: '詳細',
  },
  {
    value: 'SIGN_UP',
    label: '登録',
  },
  {
    value: 'CALL',
    label: '今すぐ電話',
  },
];

type GooglePostFormProps = {
  setHelpText: (val: HelpText | undefined) => void;
  isEditForm?: boolean;
};

export function GooglePostForm({
  setHelpText,
  isEditForm = false,
}: GooglePostFormProps) {
  const [startDate, setStartDate] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const {
    control,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<CreateGbpPostBody>();

  const postType = useWatch({
    control,
    name: 'postType',
  });
  const action = useWatch({
    control,
    name: 'action',
  });

  return (
    <>
      <FormItem
        label="投稿タイプ"
        isRequired
        onMouseEnter={() => setHelpText(undefined)}
        onMouseLeave={() => setHelpText(undefined)}
        maxW={'252px'}
        error={errors.postType}
      >
        <Controller
          control={control}
          name="postType"
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder="選択してください"
              options={postTypeOptions}
              value={postTypeOptions.find((opt) => opt.value === value)}
              onChange={(opt) => {
                if (
                  !action &&
                  (opt.value === 'STANDARD' || opt.value === 'EVENT')
                ) {
                  setValue(
                    'action',
                    {
                      applyPostLink: false,
                      type: 'ACTION_TYPE_UNSPECIFIED',
                      url: null,
                    },
                    { shouldValidate: true },
                  );
                }
                onChange(opt.value);
              }}
              isDisabled={isEditForm}
            />
          )}
        />
      </FormItem>
      {(postType === 'EVENT' || postType === 'OFFER') && (
        <>
          <FormItem
            label="タイトル"
            isRequired
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            maxW={'252px'}
            error={errors.event?.title}
          >
            <Input
              placeholder="入力してください"
              {...register('event.title')}
            />
          </FormItem>
          <FormItem
            label="期間"
            isRequired
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            error={errors.event?.startDateTime || errors.event?.endDateTime}
          >
            <Flex gap={3} alignItems="center">
              <Controller
                control={control}
                name="event.startDateTime"
                render={({ field: { onChange } }) => (
                  <Flex gap={3} alignItems="center">
                    <DatePicker
                      monthsShown={2}
                      minDate={new Date()}
                      selected={startDate ? new Date(startDate) : new Date()}
                      value={startDate.replace(/-/g, '.')}
                      onChange={(v) => {
                        if (v) {
                          setStartDate(formatDate(v, 'yyyy-MM-dd'));
                          if (startTime) {
                            onChange(
                              formatDate(v, 'yyyy-MM-dd') + ' ' + startTime,
                            );
                          }
                        }
                      }}
                    />
                    <SelectTime
                      size="md"
                      placeholder="--:--"
                      value={startTime}
                      onChange={(v) => {
                        if (v) {
                          setStartTime(v);
                          if (startDate) {
                            onChange(startDate + ' ' + v);
                          }
                        }
                      }}
                    />
                  </Flex>
                )}
              />
              -
              <Controller
                control={control}
                name="event.endDateTime"
                render={({ field: { onChange } }) => (
                  <Flex gap={3} alignItems="center">
                    <DatePicker
                      monthsShown={2}
                      minDate={new Date()}
                      selected={endDate ? new Date(endDate) : new Date()}
                      value={endDate.replace(/-/g, '.')}
                      onChange={(v) => {
                        if (v) {
                          setEndDate(formatDate(v, 'yyyy-MM-dd'));
                          if (endTime) {
                            onChange(
                              formatDate(v, 'yyyy-MM-dd') + ' ' + endTime,
                            );
                            trigger('event.startDateTime');
                          }
                        }
                      }}
                    />
                    <SelectTime
                      size="md"
                      placeholder="--:--"
                      value={endTime}
                      onChange={(v) => {
                        if (v) {
                          setEndTime(v);
                          if (endDate) {
                            onChange(endDate + ' ' + v);
                            trigger('event.startDateTime');
                          }
                        }
                      }}
                    />
                  </Flex>
                )}
              />
            </Flex>
          </FormItem>
        </>
      )}
      <FormItem
        label="写真・動画"
        onMouseEnter={() => setHelpText(undefined)}
        onMouseLeave={() => setHelpText(undefined)}
        error={errors.mediaUrl}
      >
        <EditPostAssetItemsDialog maxItem={1} />
      </FormItem>
      <Controller
        control={control}
        name="content"
        render={({ field: { value, onChange } }) => (
          <FormItem
            label="本文"
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            rightElm={
              <Text
                fontSize="sm"
                color="gray.300"
              >{`${value?.length || 0}/1500`}</Text>
            }
            error={errors.content}
          >
            <Textarea
              placeholder="入力してください"
              resize="vertical"
              rows={10}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </FormItem>
        )}
      />
      {(postType === 'EVENT' || postType === 'STANDARD') && (
        <>
          <FormItem
            label="ボタン"
            isRequired
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            maxW={'252px'}
          >
            <Controller
              control={control}
              name="action.type"
              render={({ field: { value, onChange } }) => (
                <Select
                  placeholder="選択してください"
                  options={actionTypeOptions}
                  value={actionTypeOptions.find((opt) => opt.value === value)}
                  onChange={(opt) => onChange(opt.value)}
                />
              )}
            />
          </FormItem>
          {action &&
            action.type &&
            action.type !== 'ACTION_TYPE_UNSPECIFIED' && (
              <FormItem
                label="ボタンのリンク"
                isRequired
                onMouseEnter={() => setHelpText(undefined)}
                onMouseLeave={() => setHelpText(undefined)}
                rightElm={
                  action.type !== 'CALL' ? (
                    <Controller
                      control={control}
                      name="action.applyPostLink"
                      render={({ field: { value, onChange } }) => (
                        <Flex gap={2} alignItems="center">
                          <Switch
                            isChecked={value ?? false}
                            onChange={(value) => {
                              onChange(value);
                              trigger('action.url');
                            }}
                          />
                          <Text>店舗マスタの投稿用リンクを適用</Text>
                        </Flex>
                      )}
                    />
                  ) : null
                }
                error={errors.action?.url}
              >
                {action.type === 'CALL' ? (
                  <Text>店舗マスタの「電話番号」が適用されます</Text>
                ) : (
                  <Input
                    type="url"
                    placeholder="https://"
                    {...register('action.url')}
                    isDisabled={action.applyPostLink}
                  />
                )}
              </FormItem>
            )}
        </>
      )}
      {postType === 'OFFER' && (
        <>
          <FormItem
            label="クーポンコード"
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            error={errors.coupon?.code}
          >
            <Input
              placeholder="入力してください"
              {...register('coupon.code')}
            />
          </FormItem>
          <FormItem
            label="クーポンURL"
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            rightElm={
              <>
                <Flex gap={2} alignItems="center">
                  <Switch />
                  <Text>店舗マスタの投稿用リンクを適用</Text>
                </Flex>
              </>
            }
            error={errors.coupon?.url}
          >
            <Input
              type="url"
              placeholder="入力してください"
              {...register('coupon.url')}
            />
          </FormItem>
          <FormItem
            label="クーポン利用規約"
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            error={errors.coupon?.term}
          >
            <Input
              placeholder="入力してください"
              {...register('coupon.term')}
            />
          </FormItem>
        </>
      )}
    </>
  );
}
